import classNames from 'classnames'
import { useRouter } from 'next/router'

import filtersService from '@/services/filters.service'
import { useAppSelector } from '@/store/hooks'
import { CardType, PageCardType } from '@/types/card'
import useDirectories from '@/utils/useDirectories'

import styles from '../Card.module.scss'
import ClipboardButton from '../ClipboardButton/ClipboardButton'
import DropdownButton from '../DropdownButton/DropdownButton'
import RotateButton from '../RotateButton/RotateButton'

type Props = {
  card: CardType
  filtersEnabled: boolean
  isRotated: boolean
  pageCommander?: PageCardType
  setIsRotated: (isRotated: boolean) => void
}

const CardButtons = ({ card, filtersEnabled, isRotated, pageCommander, setIsRotated }: Props) => {
  const nameFilters = useAppSelector((state) => state.filters.nameFilters)
  const { dir } = useDirectories()
  const router = useRouter()

  const nameFilterPreposition = filtersService.getNameFilterPreposition({ cardName: card.name, nameFilters })

  const className = classNames(styles.cardButton, 'edhrec-clipboard-dont-close', {
    'd-flex': nameFilterPreposition !== null,
  })
  const classNameInner = classNames(styles.cardButtonInner, 'edhrec-clipboard-dont-close')

  return (
    <div className={styles.cardButtons}>
      <ClipboardButton card={card} className={className} classNameInner={classNameInner} />
      {card.type === 'Battle — Siege' && <RotateButton isRotated={isRotated} setIsRotated={setIsRotated} />}
      <DropdownButton
        card={card}
        className={className}
        classNameInner={classNameInner}
        filtersEnabled={filtersEnabled}
        handleSubmit={() => {
          if (pageCommander) filtersService.submit({ card: pageCommander, dir, router })
        }}
      />
    </div>
  )
}

export default CardButtons

import React from 'react'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import reactStringReplace from 'react-string-replace'
import { v4 } from 'uuid'

import Mana from '@/components/Mana/Mana'
import { PageCardType } from '@/types/card'

import styles from './CardLabel.module.scss'

const getSynergyLabel = ({ label, num_decks, pageCommander, potential_decks, synergy }: Props) => {
  if (!label) return null

  if (typeof label !== 'string' || !num_decks || !pageCommander || !potential_decks || !synergy) return label

  return reactStringReplace(label, /synergy/g, (_match) => (
    <OverlayTrigger
      overlay={
        <Tooltip className={styles.synergyTooptip}>
          <span className={styles.synergyTooltipBody}>
            <span>synergy = </span>
            <span>
              <div>
                usage in {pageCommander.name.split(' // ')[0]} ({Math.round((100 * num_decks) / potential_decks)}
                %)
              </div>
              <div>
                - usage in <Mana colors={pageCommander.color_identity} /> (
                {Math.round(Math.round((100 * num_decks) / potential_decks) - 100 * synergy)}
                %)
              </div>
            </span>
          </span>
        </Tooltip>
      }
      placement='bottom'
    >
      <span className={styles.synergy}>synergy</span>
    </OverlayTrigger>
  ))
}

type Props = {
  label?: React.ReactNode
  num_decks?: number
  pageCommander?: PageCardType
  potential_decks?: number
  synergy?: number
}

const CardLabel = ({ label, num_decks, pageCommander, potential_decks, synergy }: Props) => {
  if (!label) return null

  const synergyLabel = getSynergyLabel({
    label,
    num_decks,
    pageCommander,
    potential_decks,
    synergy,
  })

  return <div className={styles.label}>{pageCommander?.is_commander ? synergyLabel : label}</div>
}

export default CardLabel

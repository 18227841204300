import classNames from 'classnames'
import { useState } from 'react'
import LazyLoad from 'react-lazyload'
import useSWR, { Fetcher } from 'swr'

import bannedImg from '@/assets/banned.png'
import unofficialImg from '@/assets/unofficial.png'
import cardService from '@/services/card.service'
import toastService from '@/services/toast.service'
import { useAppSelector } from '@/store/hooks'
import { GenericCardType } from '@/types/card'
import { CardType, PageCardType } from '@/types/card'
import { formatUrl } from '@/utils/helpers'
import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

import styles from './Card.module.scss'
import CardButtons from './CardButtons/CardButtons'
import CardImage from './CardImage'
import CardLabel from './CardLabel/CardLabel'
import CardLoading from './CardLoading/CardLoading'
import CardPrices from './CardPrices/CardPrices'
import CardSalt from './CardSalt/CardSalt'

type ArgType = { card: GenericCardType; name?: string }

const fetcher: Fetcher<CardType, ArgType> = async ({ card, name }: ArgType) => {
  let data: CardType

  if (card?.cards && card?.is_partner) {
    const card1 = await cardService.getCard(card?.cards[0]?.url as string)
    const card2 = await cardService.getCard(card?.cards[1]?.url as string)
    data = {
      ...card1,
      ...card,
      cards: [card1, card2],
      image_uris: [card1.image_uris[0], card2.image_uris[0]],
    }
  } else data = await cardService.getCard(card?.sanitized_wo || card?.sanitized || formatUrl(card?.name || name || ''))
  return data
}

type Props = {
  background?: boolean
  card?: GenericCardType
  className?: string
  filtersEnabled?: boolean
  fullCard?: CardType
  label?: React.ReactNode
  name?: string
  new?: boolean
  pageCommander?: PageCardType
}

const CardLazy = (props: Props) => {
  const { card, filtersEnabled, fullCard, name, pageCommander } = props
  const alternateArt = useAppSelector((state) => state.user.alternateArt)
  const patreonTier = usePatreonTier()
  const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const [isRotated, setIsRotated] = useState(false)

  // Fetch data
  // @ts-ignore
  const { data: fetchedData, error } = useSWR({ card, name }, fetcher)
  const data =
    fetchedData ||
    (fullCard ? { ...fullCard, url: fullCard.sanitized || formatUrl(fullCard.name || name || '') } : undefined)
  if (error) return <>Failed to load</>
  const { banned, new: isNew, prices, unofficial } = data || {}

  // image_uris
  let image_uris: string[] | undefined = undefined
  if (patreonTier === 'rare' && alternateArt) {
    if (card && card.cards && card.cards.length > 1 && card.is_partner)
      image_uris = [0, 1].map((i) =>
        card.cards
          ? alternateArt[card.cards[i]?.url || '']
            ? alternateArt[card.cards[i]?.url || ''][0]
            : data
              ? data.image_uris[i]
              : ''
          : '',
      )
    else if (data) image_uris = alternateArt[data.url || data.sanitized || ''] || data.image_uris
  } else if (data) image_uris = data.image_uris

  const handleImageLoaded = () => setImageIsLoaded(true)

  return (
    <>
      {banned ? (
        <div className={styles.banned}>
          <img alt='Banned' src={bannedImg.src} />
        </div>
      ) : unofficial ? (
        <div className={styles.banned}>
          <img alt='Unofficial' src={unofficialImg.src} />
        </div>
      ) : null}
      {data && (
        <>
          <CardButtons
            card={data}
            filtersEnabled={!!filtersEnabled}
            isRotated={isRotated}
            pageCommander={pageCommander}
            setIsRotated={setIsRotated}
          />
          {image_uris && (
            <CardImage
              image_uris={image_uris}
              isRotated={isRotated}
              name={data.name}
              names={data.names}
              onLoad={handleImageLoaded}
              url={card?.url}
            >
              {props.new || isNew ? (
                <div className={styles.new}>
                  <span>NEW</span>
                </div>
              ) : (
                <CardSalt salt={data.salt} />
              )}
            </CardImage>
          )}
        </>
      )}
      {!imageIsLoaded && <CardLoading />}
      {prices && <CardPrices prices={prices} />}
    </>
  )
}

const Card = (props: Props) => {
  const namesUnderCards = useAppSelector((state) => state.user.namesUnderCards)
  const { dir } = useDirectories()

  const name = props.card?.name || props.name || props.fullCard?.name || ''

  return (
    <div className={classNames(styles.container, props.className)}>
      {!namesUnderCards && (
        <div
          className={styles.nameWrapper}
          onClick={() => {
            toastService.createToast(`Copied ${name}`)
            navigator.clipboard.writeText(name)
          }}
        >
          <span className={styles.name}>{name}</span>
        </div>
      )}
      <LazyLoad height={359.19} once>
        <CardLazy
          {...props}
          filtersEnabled={props.pageCommander && (dir === 'commanders' || dir === 'decks')}
          pageCommander={props.pageCommander}
        />
      </LazyLoad>
      {namesUnderCards && <div className={styles.nameUnderCard}>{name}</div>}
      <CardLabel
        pageCommander={props.pageCommander}
        label={props.label || props.card?.label}
        num_decks={props.card?.num_decks}
        potential_decks={props.card?.potential_decks}
        synergy={props.card?.synergy}
      />
    </div>
  )
}

export default Card
